import React from 'react'

export default function NotFound() {
  return (
    <div>
      <h2>404</h2>
      <p>This page doesn't exist!</p>
    </div>
  )
}
